@import "_app";

.messages-container {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-height: 100%;
  overflow: hidden;
  // height: calc(100% - 50px);
}

.messages-sidebar-background {
  background-color: white;
  border-right: 1px solid #ebebeb;
}

.messages-sidebar {
  overflow: auto;
  height: calc(100vh - 113px - 90px);
  padding-bottom: 2%;
  margin: 0 20px;
}

.messages-chat-area-header {
  --chat-header-height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  background-color: white;
  min-height: var(--chat-header-height);
  border-bottom: 1px solid #ebebeb;
}

.messages-chat-area-header-info {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  line-height: 20px;
}

.messages-chat-area-header-name {
  font-size: 18px;
  font-weight: bold;
}

.messages-chat-area-header-title {
  font-size: 14px;
  color: #676971;
}

.messages-sidebar-header {
  background-color: white;
  overflow-y: hidden;
  padding-left: 20px;
  font-size: 3ch;
  height: 89px;
}

.messages-search-input {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  background-color: white;
}

.header-divider {
  margin-top: 0;
  margin-bottom: 0;
}

.chat-area-header {
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #ebebeb;
}

.message-row-reciever {
  width: 50%;
  float: left;
}
.message-row-sender {
  width: 50%;
  float: left;
}

.you-sent {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.you-received {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.conversation-footer {
  display: flex;
  position: relative;
  padding: 16px;
  border-top: 1px solid #e8e8e8;
  background: white;
  width: 100%;

  .message-input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-width: 80%;
    margin: 0;
    margin-left: 20px;
  }

  .title-container {
    width: 100%;
    margin-bottom: 8px;
  }

  .message-with-icons {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    width: 100%;

    .message-input {
      flex: 1;
    }

    .message-icons {
      display: flex;
      align-items: center;
      gap: 8px;
      padding-top: 4px;

      .emoji-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      .send-button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .emoji-container {
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1000;
  }
}

.conversation-container {
  display: flex;
  flex-direction: column;
  // padding-bottom: 60px; // synced with --chat-footer-height, for some reason var() doesn't work
  // height: calc(100vh - #{($nav-header-height + $footer-gap)});
}

.conversation-content {
  background-color: white;
  overflow-y: auto;
  flex: 1;
  padding: 12px;
  max-height: calc(100vh - #{($nav-header-height + $footer-gap)} - 90px);
  min-height: calc(100vh - #{($nav-header-height + $footer-gap)} - 90px);
}
.group-conversation-content {
  max-height: calc(100vh - #{($nav-header-height + $footer-gap)} - 40px);
}

.no-messages {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
  width: 100%;
}

.start-convo {
  font-size: larger;
}

.chatRight__items {
  margin-bottom: 1rem;
}

.chatRight__inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.convo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bubble-received {
  background-color: #f4f5f9;
  float: none;
  clear: none;
}

.bubble-sent {
  background-color: #f4f5f9;
  float: none;
  clear: none;
}

.chatRight__items > .chatRight__inner {
  display: flex;
  align-items: flex-end;
}

.chatRight__inner span img {
  border-radius: 50%;
  width: 38px;
  height: 38px;
}

.conversation-box .chatRight__items.you-sent span.time {
  padding-right: 10px;
}

.conversation-box .chatRight__items.you-sent span img {
  margin-left: 10px;
}

.conversation-box .chatRight__items.you-received span img {
  margin-right: 10px;
}

.conversation-box .chatRight__items.you-received span.time {
  padding-left: 10px;
}

.search-mentor-input {
  width: 300px;
  border-radius: 5px;
}
.flex-column-cnt {
  display: flex;
  flex-direction: row;
  padding: 20px 30px;
  justify-content: space-between;
}
.calendar-modal {
  top: 30px;
  width: 770px !important;
}
.link-book-availability {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}

.reply-message-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 50px;
  min-width: 500px;

  .reply-message-textarea {
    flex: 1;
    margin-bottom: 0;
    margin-right: 8px;
  }

  .emoji-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin: 0 8px;
  }

  .reply-message-send-button {
    margin-left: 8px;
  }

  .emoji-container {
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1000;
  }
}

.emoji-container.down {
  top: 50px;
}

.emoji-container.up {
  bottom: 50px;
}

@media (max-width: 761px) {
  .messages-sidebar-background {
    width: 100% !important;
    max-width: 761px !important;
    min-width: auto !important;
    flex-basis: auto !important;
  }
  .conversation-footer {
    width: 100vw;
  }
  .no-messages {
    display: none !important;
  }
}

/* Add styles for powered by container */
.powered-by-container {
  position: absolute;
  right: 10px;
  bottom: -30px;
  display: flex;
  align-items: center;
  gap: 50px;
  z-index: 1;
}

/* Add specific margin to the image if needed */
.powered-by-container img {
  margin-left: 30px;
}

/* Target the powered by text and logo directly */
[class*="powered-by"],
[class*="powered_by"],
[class*="poweredBy"] {
  position: absolute !important;
  right: 10px !important;
  bottom: -30px !important;
  display: flex !important;
  align-items: center !important;
  gap: 100px !important; /* Much larger gap */
  z-index: 1 !important;
}

/* Target any image inside powered by container */
[class*="powered-by"] img,
[class*="powered_by"] img,
[class*="poweredBy"] img {
  margin-left: 50px !important; /* Much larger margin */
}

.admin-training-container {
  position: relative;
  overflow: hidden; // Prevent scroll jumps
  min-height: 100vh;
  padding-bottom: 100px; // Add padding at bottom for dragging space

  .draggable-item {
    position: relative;
    background: white;
    margin-bottom: 8px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    &.is-dragging {
      background: #fafafa;
    }
  }
}

.user-list-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  z-index: 1050;
  margin-top: 4px;
}

.user-option {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f5f5f5;
  }
}

// Add these new styles for tagged users
.tagged-user {
  display: inline-block;
  color: #1890ff;
  background-color: rgba(24, 144, 255, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: 500;
  margin: 0 1px;

  &:hover {
    background-color: rgba(24, 144, 255, 0.2);
  }
}

// Add this to your existing styles
.message-text {
  white-space: pre-wrap;
  word-break: break-word;
}
